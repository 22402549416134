import React from 'react';
import intl from 'react-intl-universal';

import IconFont from '@/components/common/IconFont';

import styles from './styles.module.less';
import { menuList } from '../contant';
import Link from 'next/link';

const IpadFooter: React.FC = () => {
    return (
        <div className={styles['footer-container']}>
            <div className={styles['footer-center']}>
                <div className={styles['list-con']}>
                    {menuList.map((mItem) => (
                        <div className={styles['nav-item']} key={mItem.key}>
                            <div className={styles['nav-tit']}>
                                <span>{intl.get(mItem.name).d(mItem.name)}</span>
                            </div>
                            <ul className={styles['footer-nav-list']}>
                                {mItem.children.map((item) => (
                                    <li key={item.url}>
                                        <Link href={item.url}>
                                            <span className={styles.fileInfo}>{intl.get(item.name).d(item.name)}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
                <div className={styles['footer-logo']}>
                    <img
                        loading="lazy"
                        src="/business/oss-service/domestic-cloud-website/img/footer/footer-logo.png"
                        alt=""
                    />
                </div>
                <div className={styles['contact-us']}>
                    <IconFont className="email-icon" type="icon-youxiang" />
                    <span>{intl.get('Common.ContactInformation').d('联系方式')}</span>
                </div>
                <div className={styles['contact-email']}>
                    {intl.get('Common.ContactSalesNew').d('联系邮箱：sales02@cloud.finovy.com')}
                </div>
                {/* <div className={styles['contact-email']}>
                    {intl.get('Common.TechnicalSupport').d('技术支持：support@cloud.finovy.com')}
                </div> */}
            </div>
            <div className={styles['footer-bottom']}>
                <div className={styles['footer-copyright']}>
                    <span>Copyright © 2023 Finovy Cloud. All Rights Reserved.</span>
                    <div className={styles.pole} />
                    <span>
                        <a href="https://beian.miit.gov.cn/">{intl.get('Common.Copyright').d('粤ICP备2023105524号')}</a>
                    </span>
                </div>
            </div>
        </div>
    );
};
export default IpadFooter;
