import { Get, Post, UpdateParam } from '@fvc/request';
import type { API } from '@fvc/request';
import type { CodeSendParams, DomainConfig, GetLanguageParams, UserInfoResponse } from './typing';
const nextMtsUrl = process.env.NEXT_MTS_URL || 'https://mts-xds-dev.oss-cn-shenzhen.aliyuncs.com';

UpdateParam({ isLoginExpiredJump: true });

/** 发送邮件 node接口 */
// export const sendEmail = (params: ContactParams): API.Response<null> => {
//     return Post(`/v1/send/email`, params, { baseURL: '' });
// };

/** 获取mts多语言 */
export const getMtsLanguage = (params: GetLanguageParams): any => {
    const { lang, url = 'fvc_domestic_website' } = params;
    return Get(
        `/multilingual-translation-system/${url}/${lang}.json`,
        {},
        {
            baseURL: nextMtsUrl,
            showError: false,
        },
    );
};

/** 获取登录用户信息 */
export const getUserInfo = (): API.Response<UserInfoResponse> => {
    return Get(`/client-auth/v1/user/info`, {}, { isLoginExpiredJump: true });
};

/** 获取配置项 */
export const getDomainName = (): API.Response<DomainConfig[]> => {
    return Get(`/client-config/v1/domain-name`);
};

/** 获取配置项 */
export const codeSend = (params: CodeSendParams): API.Response<string> => {
    return Post(`/client-third-verify/v1/code/send`, params, { isLoginExpiredJump: true });
};

/** 退出登录 */
export const logout = () => {
    return Post(`/client-auth/v1/user/logout`, {}, { isLoginExpiredJump: true });
};

/** 获取单位配置项 */
export const getCurrencyConfig = () => {
    return Get<DomainConfig[]>(`/client-fund/v1/config/get`, {}, { isLoginExpiredJump: true });
};

export interface SlideSource {
    slideSource: {
        cutImage: string;
        cutImageHeight: number;
        cutImageWidth: number;
        oriImage: string;
        xpos: number;
        ypos: number;
    };
    slideToken: string;
}

// 获取校验本地配置
// eslint-disable-next-line new-cap
export const captchaLocalConfig = () =>
    Get<SlideSource>(`/cloud-common/v1/captcha/local-config`, {}, { isLoginExpiredJump: true });

// 验证本地资源
export const captchaLocalValidate = (data: { slideToken: string; slideDistance: number }) =>
    Post('/cloud-common/v1/captcha/local-validate', data, { isLoginExpiredJump: true });

interface RibbonValuePair {
    key: string;
    value: string;
}

export interface SendEmailParams {
    emailKey: 'cloud_collection' | 'cloud_consulting_collection';
    lang: 'zh-CN';
    codeToken: string;
    ribbonValue: RibbonValuePair[];
}

// 发送邮件
export const sendEmail = (data: SendEmailParams) =>
    Post('/cloud-common/v1/email/send', data, { isLoginExpiredJump: true });
