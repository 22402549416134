import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { useRouter } from 'next/router';
import IconFont from '@/components/common/IconFont';

import styles from './index.module.less';

const MCustomService: React.FC = () => {
    const timer1 = useRef<NodeJS.Timeout | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isQRShow, setIsQRShow] = useState<boolean>(false);
    const router = useRouter();
    const ref = useRef<HTMLDivElement>(null);
    const startanimate = () => {
        timer1.current = setInterval(() => {
            setIsOpen((prev) => !prev);
        }, 5000);
    };

    const handleMousOnclick = () => {
        if (!isQRShow) {
            timer1.current && clearInterval(timer1.current);
            if (!isOpen) {
                setIsOpen(true);
            }
            setIsQRShow(true);
        } else {
            router.push('/contact/');
        }
    };

    const QRDivRender = () => {
        return (
            <div className={styles.QR_Container}>
                <div className={styles.qr_body}>
                    <div className={styles.qr_img}>
                        <img
                            loading="lazy"
                            src="/business/oss-service/domestic-cloud-website/img/contact/pc/QR.jpg"
                            alt=""
                        />
                        <span className={styles.control_tip}>
                            {intl.get('Contact.PressSaveTip').d('长按保存或截图扫码获取客服专业服务')}
                        </span>
                    </div>
                    <div className={styles.info_con}>
                        <div className={styles.info_box}>
                            <div className={styles.info_left}>
                                <img
                                    loading="lazy"
                                    src="/business/oss-service/domestic-cloud-website/img/contact/pc/icons/phone.png"
                                    alt=""
                                />
                            </div>
                            <div className={styles.info_right}>
                                <span className={styles.info_lab}>{intl.get('Contact.QrPhoneLab').d('联系电话')}</span>
                                <span className={styles.info_value}>
                                    {intl.get('Contact.QrPhoneNumber').d('18938929483')}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.info_con}>
                        <div className={styles.info_box}>
                            <div className={styles.info_left}>
                                <img
                                    loading="lazy"
                                    src="/business/oss-service/domestic-cloud-website/img/contact/pc/icons/email.png"
                                    alt=""
                                />
                            </div>
                            <div className={styles.info_right}>
                                <span className={styles.info_lab}>
                                    {intl.get('Contact.QrEmailLabel').d('联系邮箱')}
                                </span>
                                <span className={styles.info_value}>
                                    {intl.get('Contact.QrEmailValue').d('sales02@cloud.finovy.com')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        startanimate();
        return () => {
            timer1.current && clearInterval(timer1.current);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                if (isQRShow) {
                    setIsOpen(false);
                    setIsQRShow(false);
                    startanimate();
                }
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [isQRShow, isOpen]);

    return (
        <>
            <div
                ref={ref}
                className={styles.container}
                onClick={() => {
                    handleMousOnclick();
                }}
            >
                <div className={classnames(styles.content, isOpen && styles.open)}>
                    <div className={styles['icon-box']}>
                        {isOpen ? (
                            <div className={styles['open-text']}>
                                <img
                                    loading="lazy"
                                    src="/business/oss-service/domestic-cloud-website/img/footer/custom-service.png"
                                    alt=""
                                />
                                <span>{intl.get('Common.PurchaseEnquiry').d('购买咨询')}</span>
                            </div>
                        ) : (
                            <IconFont type="kefu" className={styles.icon}></IconFont>
                        )}
                    </div>
                </div>
            </div>
            {isQRShow ? QRDivRender() : null}
        </>
    );
};
export default MCustomService;
