import React from 'react';
import intl from 'react-intl-universal';

import IconFont from '@/components/common/IconFont';

import styles from './styles.module.less';
import { menuList } from '../contant';
import classNames from 'classnames';
import Link from 'next/link';

const PcFooter: React.FC = () => {
    return (
        <div className={styles['footer-container']}>
            <div className={styles['footer-center']}>
                <div className={styles['footer-info']}>
                    <div className={styles['footer-left']}>
                        <div className={styles['footer-logo']}>
                            <img
                                loading="lazy"
                                src="/business/oss-service/domestic-cloud-website/img/footer/footer-logo.png"
                                alt="logo"
                            />
                        </div>
                    </div>
                    <div className={styles['footer-center']}>
                        <div className={styles['footer-right-box']}>
                            {menuList.map((mItem) => (
                                <div className={styles['nav-item']} key={mItem.key}>
                                    <p className={styles['nav-tit']}>{intl.get(mItem.name).d(mItem.name)}</p>
                                    <div className={styles['nav-line']} />
                                    <ul className={styles['footer-nav-list']}>
                                        {mItem.children.map((item) => (
                                            <li key={item.key}>
                                                <Link href={item.url}>
                                                    <span className={styles.fileInfo}>
                                                        {intl.get(item.name).d(item.name)}
                                                    </span>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles['footer-right']}>
                        <div className={styles['contact-us']}>
                            <IconFont className="email-icon" type="icon-youxiang" />
                            <span>{intl.get('Common.ContactInformation').d('联系方式')}</span>
                        </div>
                        <div className={classNames(styles['contact-public'], styles['contact-email'])}>
                            <div className={styles['contact-label']}>
                                {intl.get('Common.ContactEmailLabel').d('联系邮箱：')}
                            </div>
                            <div className={styles['constact-value']}>
                                {intl.get('Common.ContactEmailValue').d('sales02@cloud.finovy.com')}
                            </div>
                        </div>
                        <div className={classNames(styles['contact-public'], styles['contact-phone'])}>
                            <div className={styles['contact-label']}>
                                {' '}
                                {intl.get('Common.ContactPhoneLabel').d('联系电话：')}
                            </div>
                            <div className={styles['constact-value']}>
                                {intl.get('Common.ContactPhoneValue').d('18938929483')}
                            </div>
                        </div>
                        <div className={classNames(styles['contact-public'], styles['contact-address'])}>
                            <div className={styles['contact-label']}>
                                {intl.get('Common.ContactAddressLabel').d('联系地址：')}
                            </div>
                            <div className={styles['constact-value']}>
                                {intl
                                    .get('Common.ContactAdressValue')
                                    .d('深圳市宝安区海天路15号前海卓越时代广场A座2606')}
                            </div>
                        </div>

                        <div className={styles['qr-con']}>
                            {/* <div className={styles['public-qr']}>
                                <div className={styles['img-qrCode']}>
                                    <img loading="lazy"
                                        src="/business/oss-service/domestic-cloud-website/img/footer/QR-code.png"
                                        alt="qrCode"
                                    />
                                </div>
                                <div className={styles['text-qrCode']}>{intl.get('Common.QRCode').d('官方公众号')}</div>
                            </div> */}
                            <div className={styles['connect-qr']}>
                                <div className={styles['img-qrCode']}>
                                    <img
                                        loading="lazy"
                                        src="/business/oss-service/domestic-cloud-website/img/footer/Connect-QR-code.jpg"
                                        alt="qrCode"
                                    />
                                </div>
                                <div className={styles['text-qrCode']}>
                                    {intl.get('Common.ConnectQRCode').d('联系客服')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles['footer-bottom']}>
                <div className={styles['footer-copyright']}>
                    <span>Copyright © 2023 Finovy Cloud. All Rights Reserved.</span>
                    <div className={styles.pole} />
                    <span>
                        <a href="https://beian.miit.gov.cn/">{intl.get('Common.Copyright').d('粤ICP备2023105524号')}</a>
                    </span>
                </div>
            </div>
        </div>
    );
};
export default PcFooter;
