// import { openGraph } from '@/lib/helper';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import intl from 'react-intl-universal';

import { PageSeoMeta } from './constant';
import type { DefaultMeta, Favicons, SeoProps } from './type';

const favicons: Favicons[] = [
    {
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: '/business/oss-service/domestic-cloud-website/img/favicon/apple-icon-57x57.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '60x60',
        href: '/business/oss-service/domestic-cloud-website/img/favicon/apple-icon-60x60.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: '/business/oss-service/domestic-cloud-website/img/favicon/apple-icon-72x72.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: '/business/oss-service/domestic-cloud-website/img/favicon/apple-icon-76x76.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '114x114',
        href: '/business/oss-service/domestic-cloud-website/img/favicon/apple-icon-114x114.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '120x120',
        href: '/business/oss-service/domestic-cloud-website/img/favicon/apple-icon-120x120.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '144x144',
        href: '/business/oss-service/domestic-cloud-website/img/favicon/apple-icon-144x144.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: '/business/oss-service/domestic-cloud-website/img/favicon/apple-icon-152x152.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/business/oss-service/domestic-cloud-website/img/favicon/apple-icon-180x180.png',
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '192x192',
        href: '/business/oss-service/domestic-cloud-website/img/favicon/android-icon-192x192.png',
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/business/oss-service/domestic-cloud-website/img/favicon/favicon-32x32.png',
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: '/business/oss-service/domestic-cloud-website/img/favicon/favicon-96x96.png',
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/business/oss-service/domestic-cloud-website/img/favicon/favicon-16x16.png',
    },
    {
        rel: 'manifest',
        href: '/favicon/manifest.json',
    },
];
const Seo: React.FC<SeoProps> = (props) => {
    const router = useRouter();
    const defaultMeta: DefaultMeta = {
        title: 'Finovy Cloud_全球云基础设施服务提供商',
        siteName: 'Finovy Cloud_全球云基础设施服务提供商',
        keywords: 'Finovy Cloud,云服务器,云计算,云主机,VPS,GPU服务器,物理主机,IDC机房租赁,BGP网络，ICT系统集成',
        description:
            'Finovy Cloud 于 2014 年成立，总部位于新加坡，是一家云基础设施服务提供商，旨在为全球个人与机构客户提供弹性云服务器、GPU 服务器、物理主机、IDC 机房租赁等产品及服务。致力于为全球客户提供高性价比、高可用、高算力的基础设备租赁及解决方案服务。',
        url: 'https://cloud.finovy.com',
        type: 'website',
        robots: 'follow, index',
        image: '',
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const meta = {
        ...defaultMeta,
        ...props,
    };
    meta.title = props.templateTitle ? `${props.templateTitle} | ${meta.siteName}` : meta.title;

    const seoMeta = useMemo(() => {
        if (PageSeoMeta[router.asPath]) {
            return PageSeoMeta[router.asPath];
        }
        return {
            title: '',
            keywords: '',
            description: '',
        };
    }, [router]);
    const metaTitle = seoMeta.title ? intl.get(seoMeta.title) : defaultMeta.title;
    const metaKeywords = seoMeta.keywords ? intl.get(seoMeta.keywords) : defaultMeta.keywords;
    const metaDesc = seoMeta.description ? intl.get(seoMeta.description) : defaultMeta.description;
    return (
        <Head>
            <title>{metaTitle}</title>
            <meta name="robots" content={meta.robots} />
            <meta content={metaKeywords} name="keywords" />
            <meta content={metaDesc} name="description" />
            <meta property="og:url" content={`${meta.url}${router.asPath}`} />
            <link rel="canonical" href={`${meta.url}${router.asPath}`} />
            {/* Open Graph */}
            <meta property="og:type" content={meta.type} />
            <meta property="og:site_name" content={metaTitle} />
            <meta property="og:description" content={metaDesc} />
            <meta property="og:title" content={metaTitle} />
            <meta name="image" property="og:image" content={meta.image} />
            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@th_clarence" />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={metaDesc} />
            <meta name="twitter:image" content={meta.image} />
            {meta.date && (
                <>
                    <meta property="article:published_time" content={meta.date} />
                    <meta name="publish_date" property="og:publish_date" content={meta.date} />
                    <meta name="author" property="article:author" content="Theodorus Clarence" />
                </>
            )}

            {/* Favicons */}
            {favicons.map((linkProps) => (
                <link key={linkProps.href} {...linkProps} />
            ))}
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
                name="msapplication-TileImage"
                content="/business/oss-service/domestic-cloud-website/img/favicon/ms-icon-144x144.png"
            />
            <meta name="theme-color" content="#ffffff" />
        </Head>
    );
};

export default Seo;
