import type { ReactNode } from 'react';
import { useContext } from 'react';
import { useLayoutContext } from '../layout/context/useLayoutContext';

// import styles from './index.module.less';

interface IProps {
    m: ReactNode;
    ipad: ReactNode;
    pc: ReactNode;
}
const TransferCom: React.FC<IProps> = (props) => {
    const { m, ipad, pc } = props;
    const { winWidth = 1025 } = useContext(useLayoutContext); // 默认pc

    const render = () => {
        if (!winWidth) {
            return null;
        }
        if (winWidth < 768 && m) {
            return m;
        } else if (winWidth >= 768 && winWidth <= 1024 && ipad) {
            return ipad;
        } else {
            return pc;
        }
    };

    return render();

    // return (
    //     <>
    //         <div className={styles.transfer_mobile}>{m}</div>
    //         <div className={styles.transfer_ipad}>{ipad}</div>
    //         <div className={styles.transfer_pc}>{pc}</div>
    //     </>
    // );
};
export default TransferCom;
