import type { Configuration } from 'twind';

const defaultConfig: Configuration = {
  theme: {
    screens: {
      '<2xl': { max: '1535px' },
      // => @media (max-width: 1535px) { ... }

      '<xl': { max: '1279px' },

      '<lg': { max: '1023px' },
     
      '<991': { max:'991px' },

      '<md': { max: '767px' },

      '<sm': { max: '639px' },

      '<400': { max: '400px' },

      '<350': { max: '350px' },

      '<1920': { max: '1920px' },

      '>2xl': { min: '1536px' },
      // => @media (min-width: 1535px) { ... }

      '>xl': { min: '1280px' },

      '>lg': { min: '1024px' },

      '>992': { min: '992px' },

      '>md': { min: '768px' },

      '>sm': { min: '640px' },

      '>400': { min: '400px' },

      '>350': { min: '350px' },

      '>1920': { min: '1920px' },
      
      '<300': { max: '300px' },

    },
    boxShadow:{
        headerShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.12)',
        bannerShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.08)'
    }
  },
};
export default defaultConfig;
