export const PageSeoMeta: Record<
    string,
    {
        title: string;
        keywords: string;
        description: string;
    }
> = {
    '/': {
        title: 'Seo.HomeTitle',
        keywords: 'Seo.HomeKeywords',
        description: 'Seo.HomeDesc',
    },
    '/products/huawei/': {
        title: 'Seo.HuaweiTitle',
        keywords: 'Seo.HuaweiKeywords',
        description: 'Seo.HuaweiDesc',
    },
    '/products/baidu/': {
        title: 'Seo.BaiduTitle',
        keywords: 'Seo.BaiduKeywords',
        description: 'Seo.BaiduDesc',
    },
    '/products/ali/': {
        title: 'Seo.AliTitle',
        keywords: 'Seo.AliKeywords',
        description: 'Seo.AliDesc',
    },
    '/products/tencent/': {
        title: 'Seo.TencentTitle',
        keywords: 'Seo.TencentKeywords',
        description: 'Seo.TencentDesc',
    },
    '/products/serverhosting/': {
        title: 'Seo.ServerhostingTitle',
        keywords: 'Seo.ServerhostingKeywords',
        description: 'Seo.ServerhostingDesc',
    },
    '/products/serverrental/': {
        title: 'Seo.ServerrentalTitle',
        keywords: 'Seo.ServerrentalKeywords',
        description: 'Seo.ServerrentalDesc',
    },
    '/products/serverprocure/': {
        title: 'Seo.ServerprocureTitle',
        keywords: 'Seo.ServerprocureKeywords',
        description: 'Seo.ServerprocureDesc',
    },
    '/products/racksrental/': {
        title: 'Seo.RacksrentalTitle',
        keywords: 'Seo.RacksrentalKeywords',
        description: 'Seo.RacksrentalDesc',
    },
    '/products/ocr/': {
        title: 'Seo.OCRTitle',
        keywords: 'Seo.OCRKeywords',
        description: 'Seo.OCRDescription',
    },
    '/products/robot/': {
        title: 'Seo.RobotTitle',
        keywords: 'Seo.RobotKeywords',
        description: 'Seo.RobotDescription',
    },
    '/products/isms/': {
        title: 'Seo.IsmsTitle',
        keywords: 'Seo.IsmsKeywords',
        description: 'Seo.IsmsDescription',
    },
    '/products/clouddesk/': {
        title: 'Seo.CloudDeskTitle',
        keywords: 'Seo.CloudDeskKeywords',
        description: 'Seo.CloudDeskDescription',
    },
    '/products/cloudrender/': {
        title: 'Seo.CloudRenderTitle',
        keywords: 'Seo.CloudRenderKeywords',
        description: 'Seo.CloudRenderDescription',
    },
    '/about/': {
        title: 'Seo.AboutTitle',
        keywords: 'Seo.AboutKeywords',
        description: 'Seo.AboutDesc',
    },
    '/contact/': {
        title: 'Seo.ContactTitle',
        keywords: 'Seo.ContactKeywords',
        description: 'Seo.ContactDesc',
    },
    '/products/digitalhuman/': {
        title: 'Seo.DigitalHumanTitle',
        keywords: 'Seo.DigitalHumanKeywords',
        description: 'Seo.DigitalHumanDescription',
    },
    '/activity/newuser/': {
        title: 'Seo.NewUserTitle',
        keywords: 'Seo.NewUserKeywords',
        description: 'Seo.NewUserDescription',
    },
    '/products/vps/': {
        title: 'Seo.VpsTitle',
        keywords: 'Seo.VpsKeywords',
        description: 'Seo.VpsDescription',
    },
};
