import { createFromIconfontCN } from '@ant-design/icons';
interface IProps extends React.HTMLProps<HTMLSpanElement> {
    type: string;
}

const Icons = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/c/font_4337863_wh6rbl44sls.js', // 使用 config/defaultSettings  iconfontUrl
});

const IconFont: React.FC<IProps> = (props) => {
    // eslint-disable-next-line react/prop-types
    const { type, className = '', ...rest } = props;
    return (
        <Icons
            {...rest}
            className={`anticon-custom ${className}`}
            // eslint-disable-next-line react/prop-types
            type={type.includes('icon-') ? type : `icon-${type}`}
        />
    );
};

export default IconFont;
