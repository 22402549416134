// 适用于，推广追踪链接的记录仅识别UTM参数
// 链接形式
// https://cloud.finovy.cn/?utm_source=baidu&utm_medium=cpc&utm_campaign=RegistrationActivities2024Q3&utm_term=Send100&utm_content=Topbanner

/**
 * utm参数识别
 */
const setUtmCookies = () => {
    const UTM_PARAMS_KEY = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
    // 30天过期时间
    const EXPITATION_TIME = 30 * 24 * 60 * 60 * 1000;

    const searchParams = new URLSearchParams(window.location.search);
    const lowerCaseSearchParams = new URLSearchParams(
        [...searchParams.entries()].map(([key, value]) => [key.toLowerCase(), value]),
    );
    let hasUtmParams = false;

    const filteredSearchParams = new URLSearchParams(searchParams.toString());

    const utmValues = UTM_PARAMS_KEY.reduce<Record<(typeof UTM_PARAMS_KEY)[number], string | undefined>>(
        (acc, param) => {
            const value = lowerCaseSearchParams.get(param);
            acc[param] = value || '';

            if (value !== null) {
                hasUtmParams = true;

                filteredSearchParams.delete(param);
            }
            return acc;
        },
        {},
    );

    if (hasUtmParams) {
        console.info('🚀 ~ setUtmCookies ~ utmValues:', utmValues);

        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + EXPITATION_TIME);

        // 添加到主域名中
        const domain = '.' + window.location.hostname.split('.').slice(-2).join('.');

        document.cookie = `utm_params=${encodeURIComponent(JSON.stringify(utmValues))}; expires=${expirationDate.toUTCString()}; path=/; SameSite=Strict; domain=${domain}`;
        history.replaceState(
            {},
            document.title,
            `${window.location.pathname}${filteredSearchParams.toString() ? '?' + filteredSearchParams.toString() : ''}`,
        );
    }
};

export default setUtmCookies;
