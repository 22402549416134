import { Dropdown, Avatar } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';

import IconFont from '@/components/common/IconFont';

import styles from './index.module.less';
import type { UserInfoResponse } from '@/services/common/typing';
import { UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import MenuSlide from './components/MenuSlide';
import UserInfoModal from './components/UserInfoModal';
import { toConsolePath } from '@/utils/utils';
import Link from 'next/link';
interface IProps {
    useInfo?: UserInfoResponse;
}

const MHeader: React.FC<IProps> = (props) => {
    const { useInfo } = props;
    const [isShow, setIsShow] = useState<boolean>(false);
    const [isUserInfoShow, setIsUserInfoShow] = useState<boolean>(false);
    const userItems = [
        {
            label: <div className={styles['stie-item-user']}>{intl.get('Global.Login').d('登录')}</div>,
            key: 'login',
        },
        {
            label: (
                <div className={styles['stie-item-user']} id="analytics-register">
                    {intl.get('Global.Register').d('注册')}
                </div>
            ),
            key: 'register',
        },
    ];

    const toPageDropDown: MenuProps['onClick'] = ({ key }) => {
        toConsolePath({
            path: `/${key}`,
            isTarget: true,
            isNotAuth: true,
            isRedirect: true,
        });
    };

    return (
        <>
            <div className={styles['header-container']}>
                <div className={styles.menuContainer}>
                    <Link href="/" className={styles.logo}>
                        <img
                            loading="lazy"
                            src="/business/oss-service/domestic-cloud-website/img/header/header-logo.png"
                            alt="logo"
                        ></img>
                    </Link>
                    {!isUserInfoShow && (
                        <div className={styles['header-right']}>
                            {/* <Dropdown
                                placement="bottom"
                                menu={{
                                    items,
                                    onClick: changeLang,
                                }}
                                trigger={['click']}
                                getPopupContainer={(triggerNode: HTMLElement) => {
                                    return triggerNode.parentNode as HTMLElement;
                                }}
                            >
                                <div className={styles['site-box']} onClick={(e) => e.preventDefault()}>
                                    {stieObj[siteKey]}
                                    <DownOutlined
                                        className={styles['down-out-icon']}
                                        style={{
                                            color: 'rgba(2, 10, 26, 0.25)',
                                        }}
                                    />
                                </div>
                            </Dropdown> */}
                            {!!useInfo && (
                                <div
                                    className={styles['user-info']}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsUserInfoShow(true);
                                    }}
                                >
                                    <Avatar icon={<UserOutlined />}></Avatar>
                                </div>
                            )}
                            {!useInfo && (
                                <Dropdown
                                    placement="bottom"
                                    menu={{
                                        items: userItems,
                                        onClick: toPageDropDown,
                                    }}
                                    trigger={['click']}
                                    getPopupContainer={(triggerNode: HTMLElement) => {
                                        return triggerNode.parentNode as HTMLElement;
                                    }}
                                >
                                    <div
                                        className={styles['user-info-not']}
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        <IconFont type="icon-user"></IconFont>
                                    </div>
                                </Dropdown>
                            )}
                            <div
                                className={styles['icon-menu']}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsShow((prev) => {
                                        return !prev;
                                    });
                                }}
                            >
                                {isShow ? (
                                    <IconFont type="icon-close"></IconFont>
                                ) : (
                                    <IconFont type="icon-menu-more"></IconFont>
                                )}
                            </div>
                        </div>
                    )}
                    {isUserInfoShow && (
                        <div className={styles['header-right']}>
                            <div
                                className={styles['icon-menu']}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsUserInfoShow(false);
                                }}
                            >
                                <IconFont type="icon-close"></IconFont>
                            </div>
                        </div>
                    )}
                </div>
                {isShow && !isUserInfoShow && (
                    <MenuSlide
                        onClose={() => {
                            setIsShow(false);
                        }}
                    />
                )}
                {isUserInfoShow && (
                    <UserInfoModal
                        useInfo={useInfo}
                        onClose={() => {
                            setIsUserInfoShow(false);
                        }}
                    />
                )}
            </div>
        </>
    );
};
export default MHeader;
