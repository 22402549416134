// 用于mts翻译接口key传参
const apiLangMap = {
    'zh-CN': 'zh_cns',
    'zh-TW': 'zh_cnt',
    'en-US': 'en_us',
};

module.exports = {
    apiLangMap,
};
