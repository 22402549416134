import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';

import IconFont from '@/components/common/IconFont';

import styles from './index.module.less';
import { useRouter } from 'next/router';

const PcCustomService: React.FC = () => {
    const timer1 = useRef<NodeJS.Timeout | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const router = useRouter();
    // const [isShowQR, setisShowQR] = useState<boolean>(false);
    const startanimate = () => {
        timer1.current = setInterval(() => {
            setIsOpen((prev) => !prev);
        }, 5000);
    };

    const handleMouseEnter = () => {
        timer1.current && clearInterval(timer1.current);
        setIsOpen(true);
        // setisShowQR(true);
    };
    const handleMouseLeave = () => {
        // setisShowQR(false);
        startanimate();
    };
    const toContactService = () => {
        if (isOpen) {
            router.push('/contact/');
        }
    };
    const QRDivRender = () => {
        return (
            <div className={styles.QR_Container}>
                <div className={styles.QR_in_container}>
                    <div className={styles.qr_header}>
                        <span className={styles.qr_title}>{intl.get('Contact.QrTitle').d('扫一扫添加客服微信')}</span>
                    </div>
                    <div className={styles.qr_body}>
                        <div className={styles.qr_img}>
                            <img
                                loading="lazy"
                                src="/business/oss-service/domestic-cloud-website/img/contact/pc/QR.jpg"
                                alt=""
                            />
                        </div>
                        <div className={styles.info_con}>
                            <div className={styles.info_box}>
                                <div className={styles.info_left}>
                                    <img
                                        loading="lazy"
                                        src="/business/oss-service/domestic-cloud-website/img/contact/pc/icons/phone.png"
                                        alt=""
                                    />
                                </div>
                                <div className={styles.info_right}>
                                    <span className={styles.info_lab}>
                                        {intl.get('Contact.QrPhoneLab').d('联系电话')}
                                    </span>
                                    <span className={styles.info_value}>
                                        {intl.get('Contact.QrPhoneNumber').d('18938929483')}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.info_con}>
                            <div className={styles.info_box}>
                                <div className={styles.info_left}>
                                    <img
                                        loading="lazy"
                                        src="/business/oss-service/domestic-cloud-website/img/contact/pc/icons/email.png"
                                        alt=""
                                    />
                                </div>
                                <div className={styles.info_right}>
                                    <span className={styles.info_lab}>
                                        {intl.get('Contact.QrEmailLabel').d('联系邮箱')}
                                    </span>
                                    <span className={styles.info_value}>
                                        {intl.get('Contact.QrEmailValue').d('sales02@cloud.finovy.com')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            startanimate();
        }, 3000);
        return () => {
            timer && clearTimeout(timer);
            timer1.current && clearInterval(timer1.current);
        };
    }, []);

    return (
        <div className={styles.container} onMouseLeave={handleMouseLeave}>
            <div className={classnames(styles.content, isOpen && styles.open)} onClick={toContactService}>
                <div className={styles['icon-box']} onMouseEnter={handleMouseEnter}>
                    {isOpen ? (
                        <div className={styles['open-text']}>
                            <img
                                loading="lazy"
                                src="/business/oss-service/domestic-cloud-website/img/footer/custom-service.png"
                                alt=""
                            />
                            <span>{intl.get('Common.PurchaseEnquiry').d('购买咨询')}</span>
                        </div>
                    ) : (
                        <IconFont type="kefu" className={styles.icon}></IconFont>
                    )}
                </div>
            </div>
            {QRDivRender()}
        </div>
    );
};
export default PcCustomService;
