import { getDomainName, getCurrencyConfig } from '@/services/common';
import Storage from '@/utils/storage';
import { jsonParseLoop, getUrlParams } from '@/utils/utils';
import type { DomainObj } from '@/services/common/typing';

const domainNameObj: DomainObj = {
    'domain-name-buy': '',
    'domain-name-console': '',
    'domain-name-pay': '',
    'domain-name-www': '',
    'domain-name-shared': '',
};

const SOURCE_URL = ['zhihu.com', 'baidu.com', 'so.com', 'bing.com'];

// 来源网站链接携带参数处理
const fnSourceCarrying = () => {
    const referrer = document.referrer as unknown as string;
    const urlParams = getUrlParams<{ source: string }>(location.search ?? '');

    // source参数直接赋值整体URL.
    if (urlParams?.source) {
        Storage.SessionStorageService().set('sourceUrl', encodeURIComponent(location.href));
    }

    if (referrer.includes('link.zhihu.com')) {
        /**
         * 外部链接匹配，包含link.zhihu.com
         * 知乎不会携带source参数，所以需要单独处理
         * 由于是服务端渲染会导致重复加载，不能单纯判断referrer，只能判断是否包含需要拉取的网址
         */
        Storage.SessionStorageService().set('sourceUrl', `${referrer}`);
    }

    // 由于是服务端渲染会导致重复加载，不能单纯判断referrer，只能判断是否包含需要拉取的网址
    for (const url of SOURCE_URL) {
        if (referrer.includes(url)) {
            Storage.SessionStorageService().set('sourceReferrer', encodeURIComponent(referrer));
            break;
        }
    }
};

// 初始化缓存配置
const initStorage = async () => {
    try {
        const [res1, res2] = await Promise.all([getDomainName(), getCurrencyConfig()]);
        const currencyConfig = (res2?.data || []).map((item) => ({ ...item, v: jsonParseLoop(item.v) }));

        localStorage.setItem('domains', JSON.stringify(res1.data));
        // 代币单位
        localStorage.setItem('currencyConfig', JSON.stringify(currencyConfig));
        // 单独获取代币单位

        const productUnit = (currencyConfig || []).find((item) => item.k === 'finance-config');

        localStorage.setItem('productUnit', JSON.stringify(productUnit?.v?.productUnit || ''));

        res1.data.forEach((item) => {
            domainNameObj[item.k as keyof DomainObj] = item.v;
        });
    } catch (error) {}
};

/** 用于app中初始化加载 */
const appInitial = () => {
    return { initStorage, fnSourceCarrying };
};

export default appInitial;
