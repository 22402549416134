import classnames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';

import styles from './index.module.less';

import type { UserInfoResponse } from '@/services/common/typing';
import { delCookie, toConsolePath } from '@/utils/utils';
import { logout } from '@/services/common';

interface IProps {
    useInfo?: UserInfoResponse;
    onClose?: () => void;
}

const UserInfoModal: React.FC<IProps> = (props) => {
    const { onClose, useInfo } = props;
    /**
     * 退出登录
     */
    const loginOut = async () => {
        try {
            const res = await logout();
            if (res.code === 0) {
                delCookie('Authorization');
                window.location.reload();
            }
        } catch (error) {}
    };

    return (
        <>
            <div className={styles['slide-menu']}>
                <div className={classnames(styles['menu-item'], styles['menu-item-opt'])}>
                    <span>昵称：</span>
                    <span>{useInfo?.name}</span>
                </div>
                <div
                    className={styles['menu-item']}
                    onClick={() => {
                        toConsolePath({
                            path: '/account/center/detail',
                            isTarget: true,
                        });
                    }}
                >
                    {intl.get('Header.Console').d('控制台')}
                </div>
                <div className={styles['menu-item']} onClick={loginOut}>
                    {intl.get('Global.LoginOut').d('退出登录')}
                </div>
            </div>
            <div
                className={styles.mask}
                onClick={() => {
                    onClose?.();
                }}
            ></div>
        </>
    );
};
export default UserInfoModal;
