import React from 'react';

import MCustomService from './m';
import PCustomService from './pc';
import TransferCom from '../../transferCom';

const Footer: React.FC = () => {
    return <TransferCom m={<MCustomService />} ipad={<MCustomService />} pc={<PCustomService />} />;
};
export default Footer;
