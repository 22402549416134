import React from 'react';

import IpadFooter from './ipad';
import MFooter from './m';
import PcFooter from './pc';
import TransferCom from '../../transferCom';

const Footer: React.FC = () => {
    return <TransferCom m={<MFooter />} ipad={<IpadFooter />} pc={<PcFooter />} />;
};
export default Footer;
