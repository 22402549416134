import { Dropdown, Avatar } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import intl from 'react-intl-universal';
import { delCookie, toConsolePath } from '@/utils/utils';

import styles from './index.module.less';
import { navList } from '../constant';
import type { ItemsType } from '../type';
import { logout } from '@/services/common';
import type { UserInfoResponse } from '@/services/common/typing';
import Link from 'next/link';
import IconFont from '@/components/common/IconFont';

interface IProps {
    useInfo?: UserInfoResponse;
}

const PcHeader: React.FC<IProps> = (props) => {
    const { useInfo } = props;
    const headSrc = useInfo?.head || '/business/oss-service/domestic-cloud-website/img/header/avatar_active_new.png';
    const router = useRouter();
    const cls = (item: ItemsType) => {
        if (item.children && item.children.some((item2: ItemsType) => item2.url === router.asPath)) {
            return styles.act;
        }
        if (item.url === router.asPath) {
            return styles.act;
        }
        return undefined;
    };
    /**
     * 退出登录
     */
    const loginOut = async () => {
        try {
            const res = await logout();
            if (res.code === 0) {
                delCookie('Authorization');
                window.location.reload();
            }
        } catch (error) {}
    };
    const menusItems = [
        {
            label: (
                <div className={styles.logout} onClick={loginOut}>
                    <LogoutOutlined />
                    &nbsp;{intl.get('Global.LoginOut').d('退出登录')}
                </div>
            ),
            key: '1',
        },
    ];

    return (
        <div className={styles['header-container']}>
            <div className={styles.menuContainer}>
                <div className={styles['header-left']}>
                    <Link href="/" className={styles.logo}>
                        <img
                            loading="lazy"
                            src="/business/oss-service/domestic-cloud-website/img/header/header-logo.png"
                            alt="logo"
                        />
                    </Link>
                    <ul className={styles.menuList}>
                        {navList.map((item) => {
                            return (
                                <li className={styles.menuLi} key={item.id}>
                                    <Link href={item.url || ''} title={intl.get(item.text)} className={cls(item)}>
                                        {intl.get(item.text).d(item.text)}
                                    </Link>
                                    {item.id === '07' && (
                                        <span className={styles['hot-tag']}>
                                            <span>HOT</span>
                                        </span>
                                    )}
                                    {item.children && (
                                        <div className={styles['nav-box']}>
                                            {item.children.map((item2) => {
                                                return (
                                                    <Link
                                                        className={classnames(
                                                            styles['nav-item'],
                                                            item.children.length > 5 ? styles['over-length'] : null,
                                                        )}
                                                        href={item2.url}
                                                        key={item2.id}
                                                    >
                                                        <p>{intl.get(item2.text).d(item2.text)}</p>
                                                        <p>{intl.get(item2.desc).d(item2.desc)}</p>
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className={styles['header-right']}>
                    <div className={styles['header-right-con']}>
                        <div
                            className={styles.logBtn}
                            onClick={() => {
                                toConsolePath({
                                    path: '/account/center/detail',
                                    isTarget: true,
                                });
                            }}
                        >
                            <IconFont type="icon-house" className={styles.console_icon}></IconFont>
                            {intl.get('Header.Console').d('控制台')}
                        </div>
                        {!useInfo && (
                            <div
                                className={styles.logBtn}
                                onClick={() => {
                                    toConsolePath({
                                        path: '/login',
                                        isTarget: true,
                                        isNotAuth: true,
                                        isRedirect: true,
                                    });
                                }}
                            >
                                {intl.get('Global.Login').d('登录')}
                            </div>
                        )}
                        {!!useInfo && (
                            <>
                                <div className={classnames(styles.logBtn, styles['log-user-info'])}>
                                    <Dropdown
                                        menu={{ items: menusItems }}
                                        getPopupContainer={(triggerNode) => {
                                            return triggerNode;
                                        }}
                                        placement="bottom"
                                    >
                                        <div className={styles['user-info']}>
                                            <Avatar src={headSrc} size={40} alt={useInfo?.name} />
                                        </div>
                                    </Dropdown>
                                </div>
                            </>
                        )}
                    </div>

                    {!useInfo && (
                        <div
                            id="analytics-register"
                            className={styles['free-reg']}
                            onClick={() => {
                                toConsolePath({
                                    path: '/register',
                                    isNotAuth: true,
                                    isTarget: true,
                                    isRedirect: true,
                                });
                            }}
                        >
                            <div className={styles['free-reg-top']}>{intl.get('Header.Register').d('免费注册')}</div>
                            <div className={styles['free-reg-bottom']}>
                                {intl.get('Header.RegisterBottom').d('新用户赠100元')}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default PcHeader;
