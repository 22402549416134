import type { DomainObj } from '@/services/common/typing';
import { createContext } from 'react';
interface IContext {
    isLogin: boolean;
    domainNameOptions: DomainObj;
}
export const useMainContext = createContext<IContext>({
    domainNameOptions: {
        'domain-name-buy': '',
        'domain-name-console': '',
        'domain-name-pay': '',
        'domain-name-www': '',
        'domain-name-shared': '',
    },
    isLogin: false,
});
