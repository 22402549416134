import Storage from './storage';
import intl from 'react-intl-universal';
import { type DomainObj } from './types';

/**
 * @description 跳转
 **/
export const toJump = (url: string) => {
    if (!url) return;
    window.location.href = url;
};
export const evnInfo = () => {
    const href = window.location.href;
    if (href.includes('192.')) {
        return true;
    }
    if (href.includes('localhost')) {
        return true;
    }
    return false;
};
export const getDomainNameList = () => {
    const domainNameObj: Partial<DomainObj> = {};
    const domains = localStorage.getItem('domains');
    if (domains) {
        const domainsOpt = JSON.parse(domains);
        domainsOpt.forEach((item: { k: string; v: string }) => {
            domainNameObj[item.k as keyof DomainObj] = item.v;
        });
    }
    return domainNameObj;
};

export const setCookie = (name: string, value: string, timer?: string | number, domain?: string) => {
    const exp: Date = new Date();
    exp.setHours(exp.getHours() + 2);
    const localEnvFlag = evnInfo();
    let domainStr = ''; // domainsCookie['domain-name-shared'];

    if (localEnvFlag) {
        domainStr = 'localhost';
    } else {
        const hostname = window.location.hostname;
        const nameList = hostname.split('.');
        domainStr = `.${nameList[nameList.length - 2]}.${nameList[nameList.length - 1]}`;
    }
    document.cookie = `${name}=${value}; expires=${timer || exp.toUTCString()};domain=${domainStr}; path=/`;
};

// 获取cookie
export const getCookie = (name: string) => {
    try {
        let arr;
        const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
        if ((arr = document.cookie.match(reg))) {
            return unescape(arr[2]);
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
};

export const delCookie = (name: string) => {
    const exp: any = new Date();
    exp.setTime(exp.getTime() - 1);
    const cval = getCookie(name);
    const localEnvFlag = evnInfo();
    const domainsCookie = getDomainNameList();
    let domainStr = domainsCookie['domain-name-shared'];

    if (localEnvFlag) {
        domainStr = 'localhost';
    }
    if (cval != null) {
        document.cookie = `${name}=${cval};expires=${exp.toGMTString()};domain=${domainStr}; path=/`;
    }
};

const handleSourceCarrying = (rcUrl: string) => {
    // 来源地址 - 记录跳转地址
    const sourceReferrer = Storage.SessionStorageService()?.get('sourceReferrer') ?? '';
    // 来路页地址
    const sourceRouteUrl = Storage.SessionStorageService()?.get('sourceUrl') ?? '';
    // 登陆页地址
    const sourceLoginUrl = encodeURIComponent(location.href ?? '');
    // 注册页地址
    const sourceRegUrl = encodeURIComponent(rcUrl);

    return addQueryToUrl(rcUrl, {
        sourceRouteUrl,
        sourceLoginUrl,
        sourceRegUrl,
        sourceReferrer,
    });
};

/**
 *
 * @param params
 */

// 订购页面跳转处理
export const toBuyPath = (params: { path: string; isTarget?: boolean; isReplace?: boolean }) => {
    const { protocol } = window.location;
    const evnFlag = evnInfo();
    const domainOpt = getDomainNameList();
    let url = `${domainOpt['domain-name-buy']}${params.path}`;
    if (evnFlag) {
        url = `${protocol}//localhost:8002${params.path}`;
    }
    if (!evnFlag && !getCookie('Authorization')) {
        url = `${domainOpt['domain-name-console']}/login?redirectUrl=${encodeURIComponent(url)}`;
    }

    // 添加来源信息
    url = handleSourceCarrying(url);

    if (params.isTarget) {
        window.open(url);
    } else if (params.isReplace) {
        window.location.replace(url);
    } else {
        window.location.href = url;
    }
};

// 控制台跳转
export const toConsolePath = (params: {
    path: string;
    isTarget?: boolean;
    isReplace?: boolean;
    isNotAuth?: boolean;
    isRedirect?: boolean;
}) => {
    const { protocol, href } = window.location;
    const evnFlag = evnInfo();
    const domainOpt = getDomainNameList();
    let url = `${domainOpt['domain-name-console']}${params.path}${
        params.isRedirect ? `?redirectUrl=${encodeURIComponent(href)}` : ''
    }`;
    if (evnFlag) {
        url = `${protocol}//localhost:8003${params.path}${
            params.isRedirect ? `?redirectUrl=${encodeURIComponent(href)}` : ''
        }`;
    }

    if (!evnFlag && !params.isNotAuth && !getCookie('Authorization')) {
        url = `${domainOpt['domain-name-console']}/login?redirectUrl=${encodeURIComponent(url)}`;
    }

    // 添加来源信息
    url = handleSourceCarrying(url);

    console.log('url', url);

    if (params.isTarget) {
        window.open(url);
    } else if (params.isReplace) {
        window.location.replace(url);
    } else {
        window.location.href = url;
    }
};

export const numberRetain = (num: number, decimal: number) => {
    let numStr = num.toString();
    const index = numStr.indexOf('.');
    if (index !== -1) {
        numStr = numStr.substring(0, decimal + index + 1);
    } else {
        numStr = numStr.substring(0);
    }
    return parseFloat(numStr).toFixed(decimal);
};

/**
 * 格式化jsonParse 防止传入参数非json
 * @param jsonString
 * @param errorObejct
 * @returns
 */
export const jsonParse = <T = any>(jsonString: string, errorObejct?: T): T => {
    try {
        return JSON.parse(jsonString);
    } catch {
        return errorObejct ?? ({} as any);
    }
};

// 递归解析 JSON 字符串并访问深层嵌套数据
export function jsonParseLoop(jsonData: string) {
    const data = jsonParse(jsonData);
    for (const key in data) {
        if (['Object', 'Boolean', 'Array'].includes(Object.prototype.toString.call(data[key]))) {
            data[key] = jsonParseLoop(jsonParse(data[key]));
        }
    }
    return data;
}

/**
 * 解析url
 * @param urlOrigin
 * @returns
 */
export const getUrlParams = <T = Record<string, any>>(urlOrigin: string): T => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    if (!urlOrigin) return {} as T;
    const url = urlOrigin.split('?')[1];
    const obj = {}; // 声明参数对象
    const arr = url?.split('&'); // 以&符号分割为数组
    for (let i = 0; i < arr?.length; i++) {
        const arrNew = arr?.[i].split('='); // 以"="分割为数组
        (obj as any)[`${arrNew[0]}`] = arrNew[1];
    }
    // @ts-expect-error
    return obj;
};

const getUrlQuery = (_url: string) => {
    const url = _url || window.location.href || '';
    const result = {};
    if (url === '') return result;
    const pairs = url.includes('?') ? url.split('?')[1].split('&') : [];
    pairs.forEach((item) => {
        // 只查找到第一个"＝"符号，这样就不会把token中等号也裁剪掉
        const index = item.indexOf('=');
        const name = item.substr(0, index);
        let value = item.substr(index + 1);
        value = decodeURIComponent(value);
        (result as any)[name] = value;
    });
    return result;
};

/**
 * @param param { [key:string]:any}
 * @param url {string}
 * @return {string}
 */
export const addQueryToUrl = function (url: string, param: Record<string, any>) {
    if (!url) return '';
    if (!param || !Object.keys(param).length) return url;
    let _url = url;
    // 有问号提出url并添加至obj，没问号用原url
    if (url.includes('?')) {
        param = Object.assign(
            {},
            {
                ...param,
                ...getUrlQuery(url),
            },
        );
        _url = url.split('?')[0];
    }
    let result = '?';
    for (const key in param) {
        if (param[key] !== undefined && param[key] !== null) {
            result += `${key}=${param[key]}&`;
        }
    }
    // 去除最后一个& 并返回
    return `${_url}${result.slice(0, -1)}`;
};

/**
 * 获取html lang
 * @returns
 */
export const getHtmlLang = (l: string) => {
    // 简体中文页面：html lang=zh-cmn-Hans
    // 繁体中文页面：html lang=zh-cmn-Hant
    // 英语页面：html lang=en

    const langMts: Record<string, string> = {
        'zh-CN': 'zh-cmn-Hans',
        'zh-TW': 'zh-cmn-Hant',
        'en-US': 'en',
    };

    return langMts[l] || 'en';
};

/**
 * intl 多语言
 * @param parmas
 * @returns
 */
export const intlGet = (parmas: { name: string; alias: string }) => intl.get(parmas?.name).d(parmas?.alias);
