import React, { useEffect, useState } from 'react';
import { getUserInfo } from '@/services/common';
import type { UserInfoResponse } from '@/services/common/typing';
import { getCookie } from '@/utils/utils';
import TransferCom from '../../transferCom';
import PcHeader from './pc/index';
import MHeader from './m/index';

interface IProps {
    isNeedMenu?: boolean;
}
const Header: React.FC<IProps> = (props) => {
    const [useInfo, setUserInfo] = useState<UserInfoResponse>();
    useEffect(() => {
        const token = getCookie('Authorization');
        if (token) {
            getUserInfo().then((res) => {
                if (res.code === 0) {
                    setUserInfo(res.data);
                }
            });
        }
    }, []);
    return (
        <TransferCom
            m={<MHeader {...props} useInfo={useInfo} />}
            ipad={null}
            pc={<PcHeader {...props} useInfo={useInfo} />}
        />
    );
};
export default Header;
