import classnames from 'classnames';
import React, { useState } from 'react';
import intl from 'react-intl-universal';

import IconFont from '@/components/common/IconFont';

import styles from './styles.module.less';
import { menuList } from '../contant';
import Link from 'next/link';

const MFooter: React.FC = () => {
    const [navigateMap, setNavigateMap] = useState(menuList);

    const menuRender = () => {
        return navigateMap.map((item: any, index: number) => {
            return (
                <div key={item.key} className={styles['menu-item']}>
                    <div
                        className={styles['item-con']}
                        onClick={() => {
                            setNavigateMap((prev) => {
                                const list = prev.concat([]);
                                list.forEach((item, idx) => {
                                    if (index === idx) {
                                        item.isOpen = !item.isOpen;
                                    } else {
                                        item.isOpen = false;
                                    }
                                });
                                return list;
                            });
                        }}
                    >
                        <p className={styles['footer-nav-tit']}>{intl.get(item.name).d(item.name)}</p>
                        <IconFont
                            className={classnames(styles.xiaojiangtou, item.isOpen ? styles.act : null)}
                            type="icon-jiantou"
                        />
                    </div>
                    <div className={styles['parting-line']} />
                    {item.isOpen &&
                        item.children.map((subItem: any) => {
                            return (
                                <div className={styles['sub-item']} key={subItem.key}>
                                    <Link href={subItem.url}>{intl.get(subItem.name).d(subItem.name)}</Link>
                                </div>
                            );
                        })}
                </div>
            );
        });
    };

    return (
        <div className={styles['footer-container']}>
            <div className={styles['footer-top-con']}>
                <div className={styles['footer-logo']}>
                    <img
                        loading="lazy"
                        src="/business/oss-service/domestic-cloud-website/img/footer/footer-logo.png"
                        alt=""
                    />
                </div>
                {/* <div className={styles['img-qrCode']}>
                    <img loading="lazy" src="/business/oss-service/domestic-cloud-website/img/footer/QR-code.png" alt="qrCode" />
                    <span>{intl.get('Common.QRCode').d('官方公众号')}</span>
                </div> */}
                <div className={styles['img-qrCode']}>
                    <img
                        loading="lazy"
                        src="/business/oss-service/domestic-cloud-website/img/footer/Connect-QR-code.jpg"
                        alt="qrCode"
                    />
                    <span>{intl.get('Common.ConnectQRCode').d('联系客服')}</span>
                </div>
            </div>
            <div className={styles['footer-info']}>
                <div className={styles['footer-nav-menu']}>{menuRender()}</div>
                <div className={styles['contact-us']}>
                    <div className={styles['email-icon']}>
                        <IconFont type="icon-youxiang" />
                    </div>
                    <span>{intl.get('Common.ContactInformation').d('联系方式')}</span>
                </div>
                <div className={classnames(styles['contact-public'], styles['contact-email'])}>
                    <div className={styles['contact-label']}>
                        {intl.get('Common.ContactEmailLabel').d('联系邮箱：')}
                    </div>
                    <div className={styles['constact-value']}>
                        {intl.get('Common.ContactEmailValue').d('sales02@cloud.finovy.com')}
                    </div>
                </div>
                <div className={classnames(styles['contact-public'], styles['contact-phone'])}>
                    <div className={styles['contact-label']}>
                        {intl.get('Common.ContactPhoneLabel').d('联系电话：')}
                    </div>
                    <div className={styles['constact-value']}>
                        {intl.get('Common.ContactPhoneValue').d('18938929483')}
                    </div>
                </div>
                <div className={classnames(styles['contact-public'], styles['contact-address'])}>
                    <div className={styles['contact-label']}>
                        {intl.get('Common.ContactAddressLabel').d('联系地址：')}
                    </div>
                    <div className={styles['constact-value']}>
                        {intl.get('Common.ContactAdressValue').d('深圳市宝安区海天路15号前海卓越时代广场A座2606')}
                    </div>
                </div>
                {/* <div className={styles['contact-email']}>
                    {intl.get('Common.TechnicalSupport').d('技术支持：support@cloud.finovy.com')}
                </div> */}
            </div>
            <div className={styles['footer-bottom']}>
                <div className={styles['footer-copyright']}>
                    <span>Copyright © 2023 Finovy Cloud. All Rights Reserved.</span>
                    {/* <span>
                        <a href="https://beian.miit.gov.cn/">{intl.get('Common.Copyright').d('粤ICP备2023105524号')}</a>
                    </span> */}
                </div>
            </div>
        </div>
    );
};
export default MFooter;
