import React, { useEffect, useState } from 'react';

import { useLayoutContext } from './context/useLayoutContext';
import CustomService from './CustomService';
import Footer from './Footer/index';
import Header from './Header/index';
interface LayoutProps {
    isNeedHeader?: boolean;
    isNeedFooter?: boolean;
    isNeedCustomService?: boolean;
    isNeedMenu?: boolean;
}
const Layout: React.FC<LayoutProps> = (props) => {
    const { isNeedHeader = true, isNeedFooter = true, children, isNeedCustomService = true, isNeedMenu = true } = props;
    const [winWidth, setWinWidth] = useState<number>();
    useEffect(() => {
        let width = document.body.clientWidth;
        setWinWidth(width);
        let timer: NodeJS.Timeout | null = null;
        const widthResize = () => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                width = document.body.clientWidth;
                setWinWidth(width);
            }, 150);
        };
        window.addEventListener('resize', widthResize);
        return () => {
            window.removeEventListener('resize', widthResize);
            timer && clearTimeout(timer);
        };
    }, []);

    return (
        <useLayoutContext.Provider value={{ winWidth }}>
            {isNeedHeader && <Header isNeedMenu={isNeedMenu} />}
            {children}
            {isNeedFooter && <Footer />}
            {isNeedCustomService && <CustomService></CustomService>}
        </useLayoutContext.Provider>
    );
};

export default Layout;
