import React, { useEffect, useLayoutEffect } from 'react';
import Router from 'next/router';
import { Inspector } from 'react-dev-inspector';
import intl from 'react-intl-universal';
import withTwindApp from '@twind/next/app';
import Layout from '@/components/common/layout/Layout';
import NProgress from 'nprogress';

import Seo from '@/components/common/Seo';

// import { getMtsLanguage } from '@/services/common';
// console.log(getMtsLanguage);
import twindConfig from '../../twind.config';
import { useMainContext } from '@/context';
import { getCookie, setCookie, delCookie } from '@/utils/utils';
import appInitial from '@/utils/appInitial';
import utmRecognition from '@/utils/utmRecognition';

import { getMtsData } from '@/utils/cache';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import type { ReactElement, ReactNode } from 'react';
import type { DomainObj } from '@/services/common/typing';
import { apiLangMap } from '@/constant/common';

import '@/styles/globals.less';
import 'antd/dist/antd.min.css';
import '@/assets/fonts/font.css';
import { UpdateParam } from '@fvc/request';
import 'nprogress/nprogress.css';

UpdateParam({
    isLoginExpiredJump: true,
    baseURL: !globalThis.window ? process.env.NODE_API_HOST + '/business' : '/business',
});

function withDevInspector(children: any) {
    return <Inspector>{children}</Inspector>;
}

// 用于Lang[Key]历史遗留问题转换
const oldIntlLangKeyMap: Record<string, string> = {
    'zh-WT': 'zh-TW',
    'zh-CNT': 'zh-TW',
};

let lang = 'zh-CN';
let mainIsLogin = false;

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

const domainNameObj: DomainObj = {
    'domain-name-buy': '',
    'domain-name-console': '',
    'domain-name-pay': '',
    'domain-name-www': '',
    'domain-name-shared': '',
};

NProgress.configure({
    minimum: 0.3,
    easing: 'ease',
    speed: 500,
    showSpinner: false,
});

Router.events.on('routeChangeStart', () => {
    NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
    const { initStorage, fnSourceCarrying } = appInitial();

    // const router = useRouter();
    const langKey = pageProps.lang;
    // router.locale = lang;

    const langList = (pageProps.lang || navigator.language || 'zh-CN').split('-');
    lang = langList[0] + '-' + langList[langList.length - 1].toLocaleUpperCase();

    intl.init({
        currentLocale: lang,
        locales: {
            [lang]: pageProps.localeData,
        },
    });

    useLayoutEffect(() => {
        // 旧来源追踪链接
        fnSourceCarrying();
        // 注册追踪链接参数识别
        utmRecognition();
    }, []);

    // 域名配置和价格单位配置识别
    useEffect(() => {
        console.log('测试 console.log');
        // 纠错有问题的cookie，在getInitialProps中已经修复正常，页面当中直接读取判断
        if (getCookie('lang') !== langKey) {
            delCookie('lang');
            setCookie('lang', langKey, 7);
            localStorage.setItem('lang', langKey);
        }

        const authorization = getCookie('Authorization');

        if (authorization) {
            mainIsLogin = true;
        } else {
            mainIsLogin = false;
        }

        initStorage();
    }, [pageProps]);

    const getLayout = Component.getLayout ?? ((page: any) => <Layout>{page}</Layout>);

    // 初始化加载的时候会读取一遍错误的值，这里还需要纠错一下错误的langKey
    let intlProviderLangKey = lang;
    if (oldIntlLangKeyMap[intlProviderLangKey]) {
        // 修复lang[WT]错误的情况
        intlProviderLangKey = oldIntlLangKeyMap[lang];
    }

    const normalComponent = (
        <>
            <Seo />
            <useMainContext.Provider value={{ domainNameOptions: domainNameObj, isLogin: mainIsLogin }}>
                {getLayout(<Component {...pageProps} />)}
            </useMainContext.Provider>
        </>
    );

    return process.env.NEXT_PUBLIC_ENV === 'dev' ? withDevInspector(normalComponent) : normalComponent;
};

// 初始化oss语言
App.getInitialProps = async ({ Component, ctx }: any) => {
    let localeDataList = null;
    let pageProps: Record<string, any> = {};
    // 获取cookies中的语言
    let cookieLang = '';
    if (!global.window) {
        cookieLang = ctx?.req?.cookies?.lang || 'zh-CN';
    } else {
        cookieLang = getCookie('lang') || 'zh-CN';
    }

    if ((!global.window ? ctx?.req?.cookies : document.cookie).lang) {
        // 修复lang[WT]错误的情况
        if (oldIntlLangKeyMap[cookieLang]) {
            const newLangValue = oldIntlLangKeyMap[cookieLang]; // 新的语言值
            cookieLang = newLangValue;
        }
    }

    try {
        // 防止其他类型值匹配进来没有语言包匹配的情况
        const langJson = (apiLangMap as any)[cookieLang || 'zh-CN'];

        // const [res, commonRes] = await Promise.all([
        //     getMtsLanguage({ lang: langJson }),
        //     getMtsLanguage({ lang: langJson, url: 'fvc_common' }),
        // ]);
        localeDataList = await getMtsData(langJson);

        pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
        pageProps = { ...pageProps, lang: cookieLang, localeData: localeDataList };
    } catch (error) {
        pageProps = { ...pageProps, lang: cookieLang, localeData: {} };
    }

    return {
        pageProps,
    };
};
export default withTwindApp(twindConfig, App);
